.Avatar {
}

.Avatar .add {
  margin-left: auto;
  min-width: 200px;
}

.Avatar .avatars {
  display: flex;
  flex-wrap: wrap;
  gap: 19px;
  margin-top: 12px;
}

.Avatar .avatars .user {
  background-color: white;
  min-width: 264px;
  flex: 1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 12px;
  position: relative;
  box-shadow: 0px 3.22px 24.17px 0px #2e2d740d;
  max-width: 600px;
  width: 25%;
}

.Avatar .about {
  position: absolute;
  left: 30px;
  top: 12px;
  transform: rotate(90deg);
  color: #858d9d;
  cursor: pointer;
}

.Avatar img {
  height: 64px;
  width: 64px;
  display: flex;
  border-radius: 50%;
  object-fit: cover;
}

.Avatar .name {
  margin-top: 12px;
  color: #1d1f2c;
  font-size: 11.28px;
  font-weight: 600;
  line-height: 16.11px;
  letter-spacing: 0.005em;
  text-align: center;
}

.Avatar .status {
  padding: 4px 6px;
  background: #d8e6ff;
  margin-top: 4px;
  border-radius: 5px;
  font-size: 9.67px;
  font-weight: 600;
  line-height: 14.5px;
  letter-spacing: 0.005em;
  text-align: center;
  color: #377dff;
}

.Avatar .line {
  margin: 12px 0;
  border-bottom: 0.81px dotted #c2c6ce;
  width: 100%;
}

.Avatar .flex {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.Avatar .user .flex > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Avatar .user .flex p {
  font-size: 9.67px;
  font-weight: 400;
  line-height: 14.5px;
  letter-spacing: 0.005em;
  text-align: center;
  color: #667085;
}

.Avatar .user .flex span {
  display: flex;
  font-size: 11.28px;
  font-weight: 500;
  line-height: 16.11px;
  letter-spacing: 0.005em;
  text-align: center;
}

.Avatar .user .flex button,
.Avatar .user .flex a {
  height: 40px;
  color: white;
  background: #377dff;
  display: flex;
  align-items: center;
  font-size: 11.28px;
  font-weight: 500;
  line-height: 16.11px;
  letter-spacing: 0.005em;
  text-align: center;
  gap: 5px;
  border-radius: 15px;
  flex: 1;
  justify-content: center;
}

.Avatar .user .flex button svg,
.Avatar .user .flex a svg {
  font-size: 20px;
}

body:has(.RightUser) .app-body {
  width: calc(100% - 400px);
}

.AddCapture {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  display: flex;
  color: white;
  text-align: center;
  padding: 34px 20px;
  flex-direction: column;
  overflow: auto;
  flex-wrap: nowrap;
}

.AddCapture .overlay {
  display: flex;
  flex-direction: column;
  z-index: 90;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #0002;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.AddCapture .add-cap {
  margin: auto;
  background-color: #000;
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 28px;
  width: 600px;
  gap: 10px;
  max-width: 100%;
  margin-bottom: 0;
}

.AddCapture .add-cap p {
  font-size: 18px;
  font-weight: 400;
}

.AddCapture .add-cap span {
  display: flex;
  margin: auto;
  font-size: 12px;
}

.AddCapture .add-cap input,
.AddCapture .add-cap select,
.AddCapture .add-cap button {
  background-color: white;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  font-size: 16px;

  padding: 0 20px;
}

.AddCapture .add-cap button {
  background-color: #e0ff22;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.AddCapture .add-cap iframe,
.AddCapture .add-cap .iframe {
  height: 250px;
  border-radius: 27px;
  width: 100%;
}

.AddCapture > button {
  width: 600px;
  gap: 10px;
  max-width: 100%;
  background-color: #000;
  margin: auto;
  margin-top: 10px;
  min-height: 50px;
  display: flex;
  border-radius: 20px;
  color: #e0ff22;
  justify-content: center;
  align-items: center;
}

.AddCapture .flex {
}

.AddCapture .flex input,
.AddCapture .add-cap select {
  flex: none;
  min-width: 100px;
}

.AddCapture .add-cap > button {
  position: relative;
}

.AddCapture .add-cap > button .upload {
  position: absolute;
  width: 100%;
  opacity: 0;
}

.autocomplete-container {
  width: 100%;
  font-style: normal !important;
}

.autocomplete-container input,
.AddCapture .add-cap select {
  width: 100%;
}
