.UserCounts {
    /* padding-top: 40px;
    padding-bottom: 40px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    min-width: 100%;
}


.UserCounts .avatar {
    flex: none;
    align-items: center;
    text-transform: capitalize;
}

.UserCounts .avatar img {
    height: 90px;
    width: 90px;
    background-color: black;
    border-radius: 20px;
}

.UserCounts .line {
    background-color: #B6B6B6;
    width: 1px;
}

.UserCounts .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.UserCounts .item h1 {
    font-family: "Clash Display";
    font-size: 42.52px;
    font-weight: 400;
    line-height: 53.86px;
    letter-spacing: 0.005em;
    text-align: left;

}

.UserCounts .item .flex {
    gap: 5px;
    font-size: 15.7px;
    font-style: italic;
    font-weight: 400;
    line-height: 19.88px;
    letter-spacing: 0.005em;
    text-align: left;

}

.UserCounts .item .flex span {
    height: 18px;
    width: 68px;
    border-radius: 9px;
    background-color: var(--primary-color);
    font-size: 12.17px;
    font-weight: 500;
    line-height: 18.25px;
    text-align: center;

}

@media(max-width:1400px) {
    .UserCounts {
        flex-wrap: wrap;
        gap: 20px;
    }

    .UserCounts>*:not(.line) {
        min-width: 40%;
        flex: 1;
        max-width: 45%;
        min-height: 160px;
    }

    .semi-circle-chart svg {
        /* height: 100%;
        margin: auto;
        display: flex;
        width: 100px; */
    }

    .UserCounts .line:nth-child(4) {
        width: 100%;
        height: 1px;
    }
  
}

@media(max-width:560px) {
    .UserCounts {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .UserCounts .line {
        width: 100%;
        height: 1px;
    }

    .UserCounts>* {
        max-width: 100% !important;
        width: 100% !important;
    }
    .UserCounts>*:not(.line) {
        
        min-height: 120px;
    }
.UserCounts .avatar{
    justify-content: center;
}
  
}