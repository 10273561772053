.chart {
    background-color: white;
    padding: 22px 20px 22px 20px;
    gap: 0px;
    border-radius: 10px;
    padding: 20px;
    flex: 1;
    z-index: 0;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 700px;
}

.chart .apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom{
    display: none;
}

.chart .chart-container {
    /* height: 200px;
    min-height: 250px; */
    flex: 1;
}
.chart  .flex p{
    display: flex;
    gap: 5px;
    align-items: center;
}
.chart  .flex p span{
    background-color: #000000;
    display: flex;
    height: 10px;
    width: 22px;
    border-radius: 5px;
}

.chart .chart-container svg{
    /* height: auto;
    width: auto; */
}

.chart .chart-container>div {
    height: 100%;
}

.chart .flex {
    padding-left: 20px;
    font-size: 14.1px;
    font-weight: 600;
    line-height: 19.03px;
    text-align: left;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.chart .values {
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.12px;
    letter-spacing: 0.5px;
    text-align: left;
    gap: 16px;
    flex-wrap: wrap;
}

.chart .values div {
    display: flex;
    gap: 8px;
    align-items: center;
}

.chart .values span {
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #695CFB;
}

.chart .values div:nth-child(2) span {
    background-color: #00C7F2;
}

.chart .values div:nth-child(3) span {
    background-color: #0FCA7A;
}

.chart .values div:nth-child(4) span {
    background-color: #F7A23B;
}

.chart p {
    font-size: 10px;
    font-weight: 400;
    line-height: 15.12px;
    letter-spacing: 0.5px;
    text-align: center;
    margin-top: 10px;
}

@media (max-width:1100px) {
    .chart {
        min-width: 100%;
        /* max-height: 600px; */
        gap: 0;
        padding: 20px;
    }
    .chart .chart-container {
        height: 250px;
    }
}

@media (max-width:560px) {
    .chart .flex {
        justify-content: space-between;
        padding: 0 10px;
    }
    .chart {
        padding-left: 5px;
        padding-right: 5px;
    }
}