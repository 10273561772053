.app-contents:has(.Descution) {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.app-body:has(.Descution) {
  display: flex;
  height: calc(100dvh - 100px);
}
.Descution * {
  font-style: normal;
  font-family: 'Clash Display';
}

.Descution {
  background-color: white;
  font-style: normal;
  display: flex;
  flex: 1;
  align-items: stretch;
  border: solid 1px #eee;
  height: 100%;
  position: relative;
}

.Descution .overlay.active {
  display: flex;

  background-color: #0002;
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

@media (max-width: 560px) {
  .app-contents:has(.Descution) .Header {
    display: none;
  }

  .app-body:has(.Descution) {
    height: 100dvh;
    padding: 0 !important;
  }
}
