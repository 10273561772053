.RightDescution {
  min-width: 300px;
  border: solid 1px #eee;
  display: flex;
  flex-direction: column;
}

.RightDescution .top {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #eee;
}

.RightDescution .top h3 {
  font-weight: 600;
}

.RightDescution .menu-dots-vertical {
  display: none;
}

.RightDescution button {
  font-size: 20px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #615ef010;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  min-width: 32px;
}

.RightDescution .body h3 {
  font-size: 20px;
  font-weight: 700;
}

.RightDescution .body {
  padding: 20px 15px;
  border-bottom: solid 1px #eee;
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.RightDescution .body:last-child {
  flex: 1;
}

.RightDescution .body h4 {
  font-size: 13px;
  font-weight: 600;
}

.RightDescution .body .info {
  margin-top: 15px;
  display: flex;
  gap: 16px;
  align-items: center;
  color: #a3a9b6;
  font-size: 16px;
}

.RightDescution .body .info svg {
  font-size: 20px;
}

.RightDescution .body .line {
  width: 100%;
  border-top: solid 1px #a3a9b6;
  margin-top: 15px;
}

.RightDescution .body .avatar {
  margin-top: 22px;
  gap: 15px;
}

.RightDescution .body .avatar img {
  height: 46px;
  width: 46px;
  object-fit: cover;
  border-radius: 11px;
}

.RightDescution .body .avatar h4 {
  font-size: 14px;
  text-transform: capitalize;
}

.RightDescution .body .avatar p {
  font-size: 12px;
  color: #000a;
}

.RightDescution .body .app {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px 15px;
  width: 100%;
}

.RightDescution .body .app h4 {
  font-size: 11px;
}

@media (max-width: 1200px) {
  .RightDescution {
    right: 0;
    top: 0;
    position: absolute;
    background-color: white;
    height: 100%;
    z-index: 99;
    width: 100%;
    display: none;
    max-width: 400px;
    box-shadow: -10px 0px 10px #0002;
  }
  .RightDescution .menu-dots-vertical {
    display: flex;
  }

  .RightDescution.active {
    display: flex;
  }

  .BodyDescution .top button {
    display: flex;
  }
}
