.InformationForm {
  display: block !important;
}
.InformationForm section {
  padding: 24px;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  margin-top: 30px;
  box-shadow: #2e2d7405 0 4px;
}
.InformationForm > .flex {
  margin-top: 30px;
  max-width: 100%;
}
.InformationForm .flex {
  flex-wrap: wrap;
  max-width: 100%;
}
.InformationForm .flex section {
  flex: 1;
  min-width: 300px;
  margin-top: 0px;
}
.InformationForm section h3 {
  font-size: 18px;
  font-weight: 700;
}

.InformationForm .input-container {
  width: 100%;
  max-width: 100%;
  margin-top: 24px;
}

.InformationForm .input-container input,
.InformationForm .input-container textarea,
.InformationForm .input-container select {
  background-color: #f9f9fc;
  font-size: 14px;
  color: #1d1f2c;
  border-radius: 8px;
}

.InformationForm .input-container textarea {
  height: 100px;
}

.InformationForm .input-container input::placeholder,
.InformationForm .input-container textarea::placeholder,
.InformationForm .input-container select::placeholder {
  color: #1d1f2c;
}

.InformationForm .input-container label {
  color: #777980;
  font-size: 14px;
}

.InformationForm .input-container li {
  display: flex;
  align-items: center;
  gap: 17px;
  margin-top: 20px;
}

.InformationForm .input-container li input,
.InformationForm .input-container li button {
  height: 25px;
  width: 25px;
  min-width: 25px;
  border: solid 1px #777980;
  display: flex;
  margin: 0;
  border-radius: 8px;
  position: relative;
  justify-content: center;
  align-items: center;
  color: transparent;
}

.InformationForm .input-container li input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.InformationForm .input-container li button.active {
  background-color: #1d1f2c;
  color: #e0ff22;
}

.InformationForm .input-container ul .flex-add {
  display: flex;
  margin-top: 20px;
  gap: 10px;
  flex-wrap: wrap;
}

.InformationForm .input-container ul .flex-add input {
  flex: 1;
  margin: 0;
  max-width: 200px;
  min-width: 200px;
}

.InformationForm .input-container ul .flex-add button {
  gap: 10px;
  display: flex;
  align-items: center;
  padding: 10px 14px;
  background-color: #e0ff2250;
  border-radius: 8px;
  color: black;
  font-weight: 700;
  min-width: 100px;
  white-space: nowrap;
  justify-content: center;
}

.InformationForm button.add {
  background-color: black;
  color: #e0ff22;
  border-radius: 10px;
  margin-left: auto;
  margin-top: 40px;
}

@media (max-width: 500px) {
  .InformationForm .input-container ul .flex-add button span {
    display: none;
  }
}
