.ChatCard {
  border: solid 1px #eaecf0;
  background-color: white;
  min-width: 280px;
  flex: 1;
  width: 280px;
  display: flex;
  flex-direction: column;
  /* max-width: 300px; */
}

.ChatCard img {
  width: 100%;
  flex: 1;
  object-fit: contain;
  background-color: white;
}

.ChatCard .body {
  padding: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 14px;
}

.ChatCard .body p {
  color: #5f6980;
}

.ChatCard .body p.or {
  font-weight: 700;
}

.ChatCard h3 {
  color: #282828;
  text-transform: capitalize;
}

.ChatCard h4 {
  font-size: 17px;
}

.ChatCard p {
  font-size: 14px;
  line-height: 20px;
  color: #5f6980;
}

.ChatCard button {
  font-size: 12px;
  width: fit-content;
  padding: 0 20px;
}

.ChatCard h4 {
  color: #3e3e59;
  font-weight: 400;
  font-size: 13px;
}
.ChatCard .flex {
  gap: 15px;
}
.ChatCard .flex button {
  height: 35px;
  width: 35px;
  max-width: 35px;
  display: flex;
  padding: 0;
  border-radius: 50%;
  background-color: #f2f4f7;
  font-size: 18px;
}

.ChatCard .flex button svg {
  margin: auto;
}

.ChatCard .flex button:hover {
  background-color: #282828;
  color: #e0ff22;
}
