.ReportingShow {
  display: flex;
  align-items: stretch;
  padding-bottom: 100px;
  gap: 20px;
}

.ReportingShow section {
  background-color: white;
  max-width: 800px;
  margin-top: 27px;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 10px #2e2d7405;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: 300;
  flex: auto;
}

.ReportingShow h3 {
  font-size: 18px;
  font-weight: 700;
}

.ReportingShow h4 {
  font-size: 14px;
  font-weight: 500;
}

.ReportingShow p {
  font-size: 14px;
}

.ReportingShow p.bg {
  background-color: #f9f9fc;
  border: solid 1px #e0e2e7;
  padding: 7px 12px;
  line-height: 1.5rem;
  border-radius: 8px;
  min-height: 100px;
}

.ReportingShow section span {
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 14px;
  display: flex;
  color: #555;
}

.ReportingShow li {
  display: flex;
  align-items: center;
  gap: 17px;
  margin-bottom: 10px;
}

.ReportingShow li button {
  height: 25px;
  width: 25px;
  display: flex;
  margin: 0;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background-color: #1d1f2c;
  color: #e0ff22;
}

.ReportingShow .files {
  margin-top: 27px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  flex: 1;
  gap: 20px;
}

.ReportingShow .files img {
  height: 146px;
  width: 146px;
}

.ReportingShow .files div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-weight: 500;
}

@media (max-width: 1080px) {
  .ReportingShow {
    flex-direction: column;
  }
}
