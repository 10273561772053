.ChatTab {
  display: flex;
  height: 60px;
  border-radius: 30px;
  border: solid 1px #e9eaec;
  overflow: hidden;

  width: fit-content;
}

.ChatTab .tab {
  padding: 0 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #989fab;
  cursor: pointer;
  text-transform: capitalize;
}

.ChatTab .tab:hover,
.ChatTab .tab.active {
  color: #e0ff22;
  background: black;
}

.ChatTab .tab:not(:first-child) {
  border-left: solid 1px #e9eaec;
}

@media (max-width: 560px) {
  .ChatTab .tab {
    padding: 0 20px;
  }
}
