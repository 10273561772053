.Table .filter {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.Table.desine th {
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
}

.Table .filter .Search,
.Table .filter > button {
  height: 44px;
  width: 220px;
  border: 1px solid #dbdfed;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Table .filter > button {
  color: #b2b2b2;
  width: 60px;
  font-size: 13.16px;
  font-weight: 400;
  line-height: 16.58px;
}

.Table .filter > button svg {
  font-size: 20px;
}

.Table .filter button.filter-button {
  width: 125px;
  gap: 4px;
}

.Table .table-container {
  display: flex;
  max-width: 100%;
  overflow: auto;
  padding-bottom: 5px;
}

.Table table {
  width: 100%;
  margin-top: 22px;
  text-align: left;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}

.Table th {
  font-size: 17.23px;
  font-weight: 600;
  line-height: 26.8px;
  color: #495057;
  background-color: #ffffff;
}

.Table td,
.Table th {
  min-height: 60px;
  padding: 20px;
  white-space: nowrap;
  background-color: #ffffff;
  text-align: center;
}

.Table tr *:first-child {
  text-align: left;
}

.Table td {
  font-size: 15px;
  justify-content: center;
}

.Table td a,
.Table td {
  color: #667085;
}

.Table td .flex {
  align-items: center;
  gap: 5px;
}

.Table tr:nth-child(2n + 1) td {
  background-color: #f9fafb;
}

.Table.desine td {
  padding: 0;
  background: none !important;
}

.Table.desine tr:nth-child(2n + 1) td div {
  background-color: white;
}

.Table.desine td div {
  background-color: white;
  height: 90px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: 20px;
}

.Table.desine tr:nth-child(2n + 1) td div {
  background-color: white;
}

.Table.desine tr:hover div {
  background-color: #000 !important;
  color: white;
}

.Table.desine tr:hover div a,
.Table.desine tr:hover .actions button {
  color: white;
}

.Table.desine td div img,
.Table.desine td div.img svg {
  height: 46px;
  width: 46px;
  margin-right: 16px;
  margin-left: 10px;
  padding: 9px;
  object-fit: contain;
  overflow: visible;
  background-color: #000;
  border-radius: 50%;
  color: #e0ff22;
}

.Table.desine tr td .img svg {
  padding: 12px;
}

.Table.desine tr:hover td div img,
.Table.desine tr:hover td div.img svg {
  background-color: #e0ff22 !important;
  color: #000;
}

.Table.desine td div button {
  background-color: #e0ff22;
  border-radius: 20px;
}

.Table.desine td:first-child div {
  border-radius: 20px 0 0 20px;

  justify-content: flex-start;
}

.Table.desine td:last-child div {
  border-radius: 0 20px 20px 0;
}

.Table .pagination {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 20px;
}

.Table .pagination button {
  height: 34px;
  width: 34px;
  background-color: #f8f9fa;
  border: 1px solid #dfe4ea;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #637381;
  color: black;
  background-color: #e0ff22;
}

.Table .pagination button:active,
.Table .pagination button.active {
  background-color: black;
  color: #e0ff22;
  border-color: transparent;
}

.Table .pagination button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.Table .avatar {
  gap: 8px;
}

.Table .avatar img {
  height: 50px;
  width: 50px;
}

.Table .avatar div {
  gap: 10px;
}

.Table span.success,
.Table span.dark {
  display: flex;
  padding: 8px 0;
  width: 80px;
  border-radius: 20px;
  background-color: #def7ec;
  justify-content: center;
  color: #495057;
  margin: auto;
}

.Table span.dark {
  background-color: #e5e5e5;
}

.Table td > * {
  margin: auto;
}

.Table td button {
  padding: 7px 15px;
  background-color: #e5e5e5;
  border-radius: 5px;
}

.Table .color-danger {
  color: #ff481fcc;
  background: transparent;
}

.Table .color-primary {
  color: #e0ff22;
  padding: 10px;
}
