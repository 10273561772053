.LeftDescution {
  /* height: 100%; */
  width: 280px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 10px #0001;
}

.LeftDescution .top {
  height: 80px;
  display: flex;
  border: solid 1px #eee;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
}

.LeftDescution h3 {
  font-weight: 600;
  font-size: 19px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.LeftDescution h4 {
  font-weight: 600;
}

.LeftDescution h3 span {
  display: inline-block;
  height: 20px;
  border-radius: 10px;
  background-color: #edf2f7;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}

.LeftDescution button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: black;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LeftDescution .body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: solid 1px #eee;
  gap: 10px;
  flex: 1;
}

.LeftDescution .avatar {
  padding: 10px;
  gap: 15px;
  cursor: pointer;
  flex: none;
  width: 100%;
  color: black;
}
.LeftDescution .avatar:hover,
.LeftDescution .avatar.active {
  background-color: #f6f6fe;
}

.LeftDescution .avatar img {
  height: 46px;
  width: 46px;
  border-radius: 11px;
}

.LeftDescution .avatar .img {
  position: relative;
}

.LeftDescution .avatar .img span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #eee;
  display: flex;
  position: absolute;
  bottom: 2px;
  right: 2px;
}

.LeftDescution .avatar .img span.active {
  background-color: #68d391;
}

.LeftDescution .avatar h4 {
  font-size: 13px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.LeftDescution .avatar h4 span {
  opacity: 0.5;
  font-size: 10px;
}

.LeftDescution .avatar div {
  max-width: calc(100% - 70px);
}

.LeftDescution .avatar p {
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 850px) {
  .LeftDescution {
    max-width: 80px;
    overflow: hidden;
  }
  .LeftDescution .top h3 {
    display: none;
  }
  .LeftDescution .body .avatar div {
    display: none;
  }

  .LeftDescution .body .avatar {
    justify-content: center;
    width: 100%;
    min-width: 100% !important;
  }
}

@media (max-width: 560px) {
  .LeftDescution {
    position: absolute;
    background-color: white;
    flex-direction: row;
    z-index: 10;
    width: 100%;
    max-width: 100%;
    align-items: stretch;
    left: 0;
    top: 70px;
  }
  .LeftDescution .top {
    padding: 0 20px;
    height: 70px;
  }
  .LeftDescution .body {
    flex-direction: row;
    height: 70px;
    overflow: auto;
    align-items: center;
  }

  .LeftDescution .body::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .LeftDescution .body .avatar {
    width: 60px;
    min-width: 60px !important;
  }
}
