.ReportingFile {
  max-width: 600px;
  padding: 80px 0;
  margin: auto;
}

.ReportingFile .SelectDocument {
  position: relative;
  width: 100%;
  height: 276px;
  overflow: hidden;
  border: 1px #a0a0a0 dashed;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  margin-bottom: 60px;
}

.ReportingFile .SelectDocument .upload {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 10;
  opacity: 0;
  /* display: flex; */
}
.ReportingFile .SelectDocument .upload input {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.ReportingFile .SelectDocument > img {
  height: 80px;
  margin-bottom: 20px;
}

.ReportingFile .SelectDocument h3 {
  font-size: 23px;
}

.ReportingFile .SelectDocument p {
  color: #7d828f;
  font-size: 14px;
  margin-top: 9px;
}

.ReportingFile .upload-progress {
  border-radius: 17px;
  border: solid 1px #a0a0a0;
  min-height: 120px;
  padding: 14px;
  margin-bottom: 24px;
}
.ReportingFile .upload-progress .flex {
  gap: 20px;
  align-items: center;
}
.ReportingFile .upload-progress img {
  object-fit: contain;
  height: 66px;
}

.ReportingFile .upload-progress .flex div {
  overflow: hidden;
}

.ReportingFile .upload-progress h4 {
  font-size: 15px;
  overflow: hidden;
}

.ReportingFile .upload-progress p {
  font-size: 10px;
  margin-top: 4px;
  overflow: hidden;
}

.ReportingFile .upload-progress .progress {
  flex: 1;
  height: 6px;
  border-radius: 3px;
  background-color: #c3c3c3;
  width: 100%;
  box-shadow: 1px 4px 10px #0002;
}

.ReportingFile .upload-progress .progress div {
  width: 0%;
  border-radius: 3px;
  height: 6px;
  background: #377dff;
}

.ReportingFile .upload-progress .close {
  margin-left: auto;
  border: solid 1px #c2bcbc;
  border-radius: 50%;
  height: 43px;
  font-size: 13px;
  width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c2bcbc;
  min-width: 43px;
}

.ReportingFile > .add {
  background-color: black;
  color: #e0ff22;
  border-radius: 10px;
  margin-left: auto;
  margin-top: 40px;
}

.ReportingFile .progress .full {
  width: 100% !important;
}

@media (max-width: 560px) {
  .ReportingFile .upload-progress {
    position: relative;
  }
  .ReportingFile .upload-progress .flex:first-child {
    flex-direction: column;
    align-items: flex-start;
  }

  .ReportingFile .upload-progress .flex .close {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
