@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --primary-font: 'ABeeZee', sans-serif;
  --secondary-font: 'Clash Display', sans-serif;
  --tertiary-font: 'ABeeZee', sans-serif;
  --primary-color: #e0ff22;
  --secondary-color: #002e45;
  --tertiary-color: #ffffff;
  --info-color: #3a73ef;
  --success-color: #84cc16;
  --warning-color: #fad12c;
  --danger-color: #dc2626;
  --primary-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --secondary-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--primary-font);
  font-style: italic;
}

.flex {
  display: flex;
}

button,
a,
input,
select {
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: none;
}

table {
  border-style: none;
  border-collapse: collapse;
}

svg.icon {
  height: 1em;
  width: 1em;
  overflow: visible;
}

button:hover {
  cursor: pointer;
}

button:active {
  /* background: url('../public/icons/fi-rr-loading.svg?v=4#icon');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; */
}

button:disabled {
  opacity: 0.5;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

ul {
  padding-left: 0;
}

i {
  position: relative;
  bottom: 2px;
}

.primary-color {
  color: var(--primary-color) !important;
}

.secondary-color {
  color: var(--secondary-color) !important;
}

.tertiary-color {
  color: var(--tertiary-color) !important;
}

.info-color {
  color: var(--info-color) !important;
}

.success-color {
  color: var(--success-color) !important;
}

.warning-color {
  color: var(--warning-color) !important;
}

.danger-color {
  color: var(--danger-color) !important;
}

.primary-bg-color {
  background-color: var(--primary-color) !important;
}

.secondary-bg-color {
  background-color: var(--secondary-color) !important;
}

.tertiary-bg-color {
  background-color: var(--tertiary-color) !important;
}

.info-bg-color {
  background-color: var(--info-color) !important;
}

.success-bg-color {
  background-color: var(--success-color) !important;
}

.warning-bg-color {
  background-color: var(--warning-color) !important;
}

.danger-bg-color {
  background-color: var(--danger-color) !important;
}

.primary-border {
  border: 1px solid var(--primary-color) !important;
}

.secondary-border {
  border: 1px solid var(--secondary-color) !important;
}

.tertiary-border {
  border: 1px solid var(--tertiary-color) !important;
}

.info-border {
  border: 1px solid var(--info-color) !important;
}

.success-border {
  border: 1px solid var(--success-color) !important;
}

.warning-border {
  border: 1px solid var(--warning-color) !important;
}

.danger-border {
  border: 1px solid var(--danger-color) !important;
}

.primary-shadow {
  box-shadow: var(--primary-shadow);
}

.secondary-shadow {
  box-shadow: var(--secondary-shadow);
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input.middle:focus {
  outline-width: 0;
}

*:focus {
  outline: none;
}

input:focus,
textarea:focus {
  outline: none;
}

input:focus,
input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
