.ReportingSteps {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  min-height: 80px;
  width: 100%;
}

.ReportingSteps ul {
  display: flex;
  gap: 12px;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  width: 100%;
  justify-content: center;
}

.ReportingSteps ul .line {
  height: 1px;
  width: 50px;
  background-color: black;
}

.ReportingSteps li {
  display: flex;
  gap: 14px;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  max-width: 25%;
}
.ReportingSteps li p {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
}
.ReportingSteps li.active,
.ReportingSteps li:hover {
  font-weight: 700;
}

.ReportingSteps li.active span,
.ReportingSteps li:hover span {
  background-color: black;
  color: #e0ff22;
}

.ReportingSteps li span {
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 2px;
  background-color: #b6b6b6;
  color: #7d7d7d;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 560px) {
  .ReportingSteps ul {
    padding: 20px;
  }
  .ReportingSteps li {
    flex-direction: column;
    text-align: center;
  }
}
