.semi-circle-chart{
    max-height: 100px;
    display: flex;
    flex-direction: column;


}

.semi-circle-chart .flex{
    
    align-items: center;
}

.semi-circle-chart .apexcharts-datalabels-group{
    transform: translate(0%,-25%);
    font-size: 22px;
    stroke: #000000;
}

.semi-circle-chart .apexcharts-datalabels-group text:first-child{
    transform: translate(0%,32%); 
    font-size: 10px;
    stroke: #555;
    font-weight: 400;
}

.semi-circle-chart .apexcharts-datalabels-group text:last-child{

}