.BodyDescution {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: visible;
  height: 100%;
}

.BodyDescution .top {
  padding: 20px;
  border: solid 1px #eee;
}

.BodyDescution .top {
  display: flex;
  align-items: center;
}

.BodyDescution .top button {
  display: none;
}

.BodyDescution {
}

.BodyDescution .top h4 {
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 600;
}

.BodyDescution .top p {
  font-size: 11px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  overflow: hidden;
}

.BodyDescution .top p span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #eee;
  display: flex;
}

.BodyDescution .top p span.active {
  background-color: #68d391;
}

.BodyDescution .top img {
  border-radius: 10px;
  object-fit: cover;
}

.BodyDescution .body {
  padding: 20px;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.BodyDescution .body .message {
  display: flex;
  gap: 15px;
}

.BodyDescution .body .message.right {
  /* text-align: right; */
  flex-direction: row-reverse;
}

.BodyDescution .body .message.right p {
  background-color: black;
  color: #e0ff22;
}

.BodyDescution .body .message p {
  padding: 7px 15px;
  background-color: #f1f1f1;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;

  max-width: 300px;
}

.BodyDescution .body .message .file {
  height: 60px;
  object-fit: contain;
}

.BodyDescution .footer {
  padding: 20px;
  display: flex;
  align-items: center;
}

.BodyDescution .footer > button {
  position: relative;
  font-size: 24px;
  margin-right: 20px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BodyDescution .footer > button span {
  position: absolute;
  font-size: 10px;
  background-color: black;
  color: #e0ff22;
  line-height: 10px;
  display: flex;
  height: 30px;
  width: 30px;
  right: 0;
  top: 0;
  overflow: hidden;
  transform: translate(50%, -50%);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.BodyDescution .footer .upload {
  opacity: 0;
  position: absolute;
}
.BodyDescution .footer form {
  border-radius: 10px;
  height: 46px;
  border: solid 2px #e2e8f0;
  display: flex;
}

.BodyDescution .footer form input {
  padding: 0 15px;
  flex: 1;
  min-width: 100px;
}

.BodyDescution .footer form button {
  padding: 0 15px;
  display: flex;
  font-size: 20px;
  align-items: center;
}

.BodyDescution .body .user {
  height: 38px;
  width: 38px;
  border-radius: 8px;
  object-fit: cover;
}

.BodyDescution .message:has(img.user) {
  margin-top: 20px;
}

@media (max-width: 560px) {
  .BodyDescution .body {
    flex: 1;
    padding-top: 80px;
  }
}
