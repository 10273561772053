.Dashboard .counts {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 29px;
  margin-top: 16px;
}

.Dashboard .chart:has(.counts) {
  min-width: min(520px, 100%);
  flex: 1;
}

.Dashboard .counts .count {
  border-radius: 10px;
  width: 145px;
  /* flex: 1; */
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 17px;
}

.Dashboard .chart button {
  display: flex;
  gap: 9px;
  color: #0f3659;
  height: 34px;
  width: 142px;
  border: 0.87px solid #c3d3e2;
  border-radius: 9px;
  align-items: center;
  justify-content: center;
}

.Dashboard .chart button svg {
  font-size: 20px;
}

.Dashboard .counts .count svg {
  height: 38px;
  width: 38px;
  min-width: 38px;
  background-color: #e0ff22;
  border-radius: 50%;
  padding: 7px;
  overflow: visible;
  box-sizing: border-box;
  color: #000000;
}

.Dashboard .counts .count:hover {
  box-shadow: 0px 4px 4px 0px #00000020;
  border: 1px solid #000000;
}

.Dashboard .counts .count .data {
  flex: 1;
}

.Dashboard .counts .count .data h5 {
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  margin-bottom: 13px;
  color: #e0ff22;
  font-weight: 700;
  font-size: 18px;
  margin-top: 10px;
}

.Dashboard .counts .count .data p {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 20.8px;
  margin-top: 10px;
  text-align: left;
  color: #fff;
}
.Dashboard .counts .count .data span {
  color: #d4d4d4;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 11px;
  text-align: left;
}

.Dashboard .counts .count .data .flex {
  font-size: 24px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  justify-content: space-between;
  align-items: flex-end;
  gap: 5px;
}

.Dashboard .counts .count .data span {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  text-align: right;
}

.Dashboard .counts .count .data .success {
  color: #04b700;
}

.Dashboard .counts .count .data .danger {
  color: #ff481fcc;
}

@media (max-width: 1400px) {
  .Dashboard .counts {
    gap: 20px;
  }

  .Dashboard .counts {
    /* max-width: 800px; */
  }

  .Dashboard .counts .count {
    /* min-width: max(40%, 240px); */
  }
}

@media (max-width: 768px) {
  .Dashboard .counts {
    justify-content: center;
  }
}
