.upload {
    position: relative;
    cursor: pointer;
}

.upload input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.upload .edit {
    position: absolute;
    height: 30px;
    width: 30px;
    background-color: red;
    border-radius: 50% !important;
    right: 4px;
    bottom: 4px;
    background-color: white;
    padding: 0px !important;
    border: solid 1px #377DFF !important;
    display: flex;
    pointer-events: none;
}

.upload .edit svg {
    font-size: 16px;
    color: #377DFF;
    margin: auto;
}