.OTP {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100dvh;
}

.OTP .Logo {
    margin: 8vh auto;
}

.OTP .info {
    display: flex;
    flex-direction: column;
    width: 345px;
    max-width: 90%;
    text-align: center;
    margin: 32px;
}

.OTP .info h2 {
     
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    color: #2A4ECA;
}

.OTP .info p {
    margin-top: 16px;
     
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #61677D;
}

.OTP .input {
    display: flex;
    gap: 16px;
    justify-content: left;
    position: relative;
    overflow: hidden;
}

.OTP .input input {
    left: 0;
    z-index: 99;
    background-color: transparent;
    font-size: 35px;
    text-align: center;
    background-color: #EAEFF5;
    height: 70px;
    width: 56px;
    border-radius: 12px;
}

.OTP button {
    width: 345px;
    height: 60px;
    background-color: #3461FD;
    color: white;
    margin-top: 60px;
    border-radius: 14px;
}

.OTP button:disabled {
    background-color: #EAEFF5;
    opacity: 1 !important;
}

.OTP h4 {
    margin-top: 16px;
    margin-bottom: 20px;
}

.OTP .success~.Logo {
    background-color: red;
}

.OTP .success {
    flex: 1;
    display: flex;
    align-items: center;
     
    font-size: 50px;
    font-weight: 600;
    line-height: 70px;
    text-align: left;
    color: #377DFF;
    gap: 22px;
}

@media (max-width:560px) {
    .OTP .input input {
        height: 60px;
        width: 50px;
        border-radius: 10px;
        font-size: 30px;
    }
    .OTP button {
        width: 314px;
    }
}