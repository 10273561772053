.VentesAnnuelles {
  background-color: black;
  border-radius: 20px;
  color: #ffffff;
  padding: 10px;
  padding-bottom: 0;
  height: 160px;
  display: flex;
  flex-direction: column;
}

.VentesAnnuelles .chart-container {
  height: auto;
  flex: 1;
}

.VentesAnnuelles .apexcharts-tooltip {
  color: black;
}

.VentesAnnuelles .apexcharts-xaxis-texts-g tspan {
  fill: white;
}

.VentesAnnuelles .apexcharts-series:nth-child(2) {
  pointer-events: none;
}
