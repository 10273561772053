.flex-top {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.flex-top div {
  padding: 20px;
  background-color: white;
  color: #858d9d;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  flex: 1;
  gap: 14px;
  min-width: 300px;
  cursor: pointer;
}

.flex-top div.active {
  background-color: black;
  color: white;
}

.flex-top span {
  /* display: flex; */
  align-items: center;
  gap: 2px;
  margin-top: 8px;
}

.flex-top button {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 8px;
  background-color: #d1d1d1;
}

.flex-top div.active button {
  background-color: #e0ff22;
}

.flex-top h2 {
  font-family: Clash Display;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 39.36px;
  letter-spacing: 0.01em;
  text-align: left;
  color: black;
}

.flex-top div.active h2 {
  color: #e0ff22;
}
