.RightUser {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  max-width: 100%;
  min-height: 100dvh;
  background-color: white;
  /* box-shadow: -14px 4px 59.5px 0px #00000040; */
  z-index: 9;
  border-radius: 8px;
  max-height: 100dvh;
  overflow: auto;
  z-index: 99;
}

.RightUser > .overlay {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  position: fixed;
  z-index: -10;
  /* cursor: pointer; */
}

.RightUser .Header {
  background-color: white;
}

.RightUser .navigation-bar {
  display: none;
}

.RightUser .Header .menu-button {
  display: none;
}

.RightUser .profile {
  padding: 32px;
  display: block;
}

.RightUser .profile h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 20px;
  font-weight: 600;
  line-height: 25.2px;
  text-align: left;
  color: #030229;
  align-items: center;
}

.RightUser .profile h2 button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: #E71D36;
  background-color: #E71D3620;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RightUser .profile img {
  box-shadow: 0px 4px 25.7px 0px #00000014;
  height: 134px;
  width: 134px;
  object-fit: cover;
  margin: auto;
  margin-top: 11px;
}

.RightUser .last-meet {
  display: flex;
  height: 73px;
  gap: 30px;
  padding: 0 16px;
  background-color: #f9f9fc;
  margin-top: 42px;
  align-items: center;
  border-radius: 16px;
}

.RightUser .video {
  height: 43px;
  width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E0FF22;
  color: black;
  border-radius: 8px;
  font-size: 20px;
}

.RightUser .last-meet p {
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  text-align: left;
}
.RightUser .last-meet span {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;
}

.RightUser .profile .add {
  width: 100%;
  margin: 0;
  max-width: 100%;
  margin-top: 42px;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.16px;
  text-align: left;
}

.RightUser h3 {
  text-align: center;
  margin-top: 11px;
  font-size: 20px;
  font-weight: 600;
  line-height: 25.2px;
  color: #030229;
}

.RightUser .info {
  display: flex;
  align-items: center;
  gap: 16px;
  color: #a3a9b6;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.16px;
  text-align: left;
  margin-top: 12px;
}

.RightUser .info svg {
  font-size: 24px;
}


.RightUser .input-container span.error{
  display: none;
}