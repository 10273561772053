.CallCalendar {
  background-color: white;
  margin-top: 40px;
  border-radius: 16px;
  display: flex;
  flex-wrap: wrap;
}

.CallCalendar .left-call {
  padding: 24px 20px;
  /* min-width: 260px; */
  flex: 1;
}

.CallCalendar .left-call .add {
  border-radius: 9px;
}

.CallCalendar .agenda-container {
  border: solid 1px #c5c5c5;
  border-radius: 17px;
  padding: 17px;
  margin-top: 40px;
}

.CallCalendar .agenda-container .flex {
  gap: 22px;
  font-size: 13px;
}

.CallCalendar .agenda-container .flex p {
  font-weight: 500;
  display: flex;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
}

.CallCalendar .agenda-container .flex p span {
  display: flex;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background-color: #a31717;
}

.CallCalendar .agenda-container button {
  height: 40px;
  border-radius: 13px;
  border: solid 1px #c5c5c5;
  width: 100%;
  max-width: 100%;
  display: flex;
  margin-top: 22px;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  color: #00000080;
  padding: 0 20px;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CallCalendar .agenda-container button svg {
  font-size: 20px;
}

.CallCalendar .left-call img {
  width: 45px;
  height: 45px;
}
.CallCalendar .left-call .line {
  background-color: #c5c5c5;
  width: 100%;
  max-width: 100%;
  margin: 22px 0;
  height: 1px;
  display: block;
}

.CallCalendar .left-call .flex.time {
  gap: 22px;
  font-size: 22px;
  font-weight: 600;
  align-items: center;
  justify-content: space-evenly;
}

.CallCalendar .left-call .flex.time svg {
  font-size: 26px;
}

.CallCalendar .left-call .add {
  min-width: 100%;
}
.CallCalendar .rbc-toolbar {
  gap: 10px;
}
.CallCalendar .rbc-btn-group:nth-child(3) button {
  border-width: 1px;
  font-size: 14px;
  box-shadow: none !important;
}
.CallCalendar .rbc-btn-group:nth-child(3) button:first-child {
  /* background-color: red; */
  border-radius: 17px 0 0 17px;
}

.CallCalendar .rbc-btn-group:nth-child(3) button:last-child {
  /* background-color: red; */
  border-radius: 0 17px 17px 0;
}

.CallCalendar .rbc-btn-group button.rbc-active {
  color: #0caf60;
  background-color: #e7f7ef;
}
.CallCalendar .rbc-btn-group:first-child button {
  border: none;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='14' viewBox='0 0 7 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.82243 13.097L0.895996 7.15688L5.82243 1.2168' stroke='%23989FAB' stroke-width='1.54106' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.CallCalendar .rbc-btn-group:first-child button:first-child {
  display: none;
}

.CallCalendar .rbc-btn-group:first-child button:nth-child(3) {
  transform: rotate(180deg);
}

.CallCalendar .rbc-calendar {
  padding: 24px 20px;
  flex: 4;
  border-left: solid 1px #c5c5c5;
  min-height: 600px;
}

.CallCalendar .rbc-toolbar-label {
  color: #21252b;
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  text-align: left;
}

.CallCalendar .overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 99;
  background-color: #03022950;
}

.CallCalendar .add-rdv {
  background-color: white;
  box-shadow: -14px 10px 10px #0002;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  border-radius: 10px;
  width: 500px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
}

.CallCalendar .add-rdv h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #555;
  font-size: 20px;
  font-weight: 400;
  width: 100%;
}

.CallCalendar .add-rdv h4 a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: #555;
  font-size: 16px;
}

.CallCalendar .add-rdv .rdv {
  background-color: #000;
  color: white;
  padding: 6px 12px;
  border-radius: 10px;
  width: fit-content;
  margin-top: 48px;
  font-size: 14px;
}

.CallCalendar .add-rdv input,
.CallCalendar .add-rdv > select {
  margin-top: 13px;
  height: 42px;
  border-radius: 10px;
  padding-left: 20px;
  border: solid 1px #c5c5c5a0;
  width: 100%;
}

.CallCalendar .add-rdv > select:disabled {
  color: #555;
}

.CallCalendar .add-rdv > select option {
  padding: 10px;
}

.CallCalendar .add-rdv .flex {
  gap: 15px;
  margin-top: 29px;
  align-items: center;
  white-space: nowrap;
}

.CallCalendar .add-rdv .flex > div:first-child {
  background-color: #0002;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CallCalendar .add-rdv .flex > div:nth-child(2) {
  max-width: calc(100% - 100px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.CallCalendar .add-rdv .flex .edit {
  margin-left: auto;
}

.CallCalendar .add-rdv .flex p {
  color: #030229;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CallCalendar .add-rdv .flex span {
  color: #010d1c90;
  font-size: 12px;
}

.CallCalendar .add-rdv .flex .buttons {
  margin-top: 42px;
  gap: 13px;
}

.CallCalendar .add-rdv .buttons > * {
  border-radius: 10px;
  padding: 0 13px;
  height: 39px;
  flex: 1;
  margin: 0;
  width: 200px;
}

.CallCalendar .add-rdv .buttons {
  display: flex;
  margin-top: 38px;
  gap: 13px;
  flex-wrap: wrap;
}

.CallCalendar .add-rdv .buttons > :first-child {
  background: #000;
  color: #377dff;
}

.CallCalendar .add-rdv .flex-buttons {
  display: flex;
  gap: 20px;
  margin-left: auto;
  margin-top: 16px;
  justify-content: flex-end;
}
.CallCalendar .add-rdv .flex-buttons button,
.CallCalendar .add-rdv .flex-buttons a {
  height: 40px;
  min-width: 100px;
  padding: 6px;
  border-radius: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CallCalendar .add-rdv .flex-buttons a:first-child {
  border: solid 1px #c5c5c5;
  color: #000;
  border: none;
}

.CallCalendar .add-rdv .flex-buttons button:last-child {
  background: #000;
  color: #e0ff22;
}

.CallCalendar .add-rdv .input {
  position: relative;
  padding: 0;
  margin: 0;
  margin-top: 13px;
  width: 100%;
}

.CallCalendar .add-rdv select.SelectUser {
  margin: 0;
  background-color: transparent;
  color: #555;
}

.CallCalendar .add-rdv .input input {
  margin-top: 0;
  padding-left: 45px;
}

.CallCalendar .rbc-event {
  justify-content: center;
  flex-direction: column-reverse;
  gap: 6px;
  font-size: 10px;
}

.CallCalendar .rbc-event .rbc-event-label {
  text-align: center;
  width: 100%;
  white-space: wrap;
}

.CallCalendar .rbc-event .rbc-event-content {
  height: auto;
  flex: none;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.CallCalendar .rbc-event.rbc-event-allday .rbc-event-content div {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  font-size: 12px;
}

.CallCalendar .rbc-event.rbc-event-allday .rbc-event-content div h4 {
  font-size: 14px;
}

.CallCalendar .add-rdv .input svg {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(0, -50%);
  color: #555;
}

.CallCalendar .add-rdv .time {
  border: solid 1px #c5c5c5;
  margin-top: 13px;
  border-radius: 10px;
  color: #555;
  width: 100%;
}
.CallCalendar .add-rdv .time .top .flex {
  margin-top: 0;
}
.CallCalendar .add-rdv .time p {
  color: #555;
  display: flex;
  align-items: center;
  gap: 5px;
}

.CallCalendar .add-rdv .time .top {
  padding: 18px;
  border-bottom: solid 1px #c5c5c5;
}

.CallCalendar .add-rdv .time .body {
  padding: 18px;
}

.CallCalendar .time .select {
  font-size: 11px;
  border: solid 1px #c5c5c5;
  border-radius: 10px;
  padding: 5px;
  margin-left: auto;
}

.CallCalendar .add-rdv .time .body input {
  padding: 5px 10px;
  height: fit-content;
}

.CallCalendar .add-rdv .time .body .flex {
  width: 100%;
  margin-top: 13px;
}

.CallCalendar .add-rdv .time .body .flex div {
  flex: 1;
  background-color: transparent;
  border-radius: 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: capitalize;
}

.CallCalendar .add-rdv .time .body button {
  border: solid 1px #c5c5c5;
  color: #555;
  flex: 1;
  height: 33px;
  border-radius: 10px;
  position: relative;
}

.CallCalendar .add-rdv .time .body button input {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  display: flex;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.CallCalendar .add-rdv .time .body button:has(input:checked) {
  background-color: #000;
  color: #e0ff22;
}

.CallCalendar .add-rdv .time .body button:nth-child(2) {
  margin-left: 20px;
}

.CallCalendar .add-rdv .time .body button:last-child {
  border: solid 1px #ba1717;
  color: #ba1717;
}
.input:has(.autocomplete-container) {
  position: relative;
}

.CallCalendar .autocomplete-container {
  position: absolute;
  /* opacity: 0; */
  z-index: 1;
}
.CallCalendar .autocomplete-container {
  opacity: 0;
}

.CallCalendar .autocomplete-container:has(input:focus) {
  opacity: 1;
}

.CallCalendar .autocomplete-container:has(input:focus) + input {
  opacity: 0;
}

@media (max-width: 1100px) {
  .CallCalendar {
    flex-direction: column;
  }
}
